<template>
  <div>
    <topbar drawer :title="navbarTitle"></topbar>
    <v-container v-if="!loading">
      <v-row>
        <v-col>
          <v-skeleton-loader elevation="2" type="card" :loading="false" color="lightgreen">
            <v-img
              src="https://wallpaperaccess.com/full/1162775.jpg"
              class="white--text align-end"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              height="200px"
            >
              <v-card-title class="text-h5">Playlist</v-card-title>
              <v-card-subtitle>Du kannst hier die Musik List vom Discord Bot bearbeiten</v-card-subtitle>
            </v-img>
            <v-card-text>
              <v-list three-line>
                <draggable
                  v-model="playlist"
                  handle=".handle"
                  v-bind="dragOptions"
                  @start="drag = true"
                  @end="onDragEnd"
                >
                  <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                    <div v-for="(item, index) in playlist" :key="index">
                      <v-list-item>
                        <template v-if="!editActive.includes(item.id)">
                          <v-list-item-icon style="width: 5px">
                            <v-icon :style="{pointerEvents: drag ? '' : 'none'}" class="mr-4 move--btn handle" size="medium">fa-arrows-alt</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{item.name}}
                            </v-list-item-title>
                            <v-list-item-subtitle><a :href="item.url" target="_blank">{{item.url}}</a></v-list-item-subtitle>
                            <v-list-item-subtitle>Hinzugefügt von: {{item.userName}}</v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-btn icon @click="onEdit(item.id)">
                              <v-icon color="yellow darken-3">
                                fa-edit
                              </v-icon>
                            </v-btn>
                          </v-list-item-action>
                          <v-list-item-action>
                            <v-btn icon @click="onDelete(item.id)">
                              <v-icon color="yellow darken-3">
                                fa-trash
                              </v-icon>
                            </v-btn>
                          </v-list-item-action>
                        </template>
                        <div style="width: 100%" :hidden="!editActive.includes(item.id)">
                          <v-list-item-content>
                            <ValidationObserver ref="observer">
                              <ValidationProvider v-slot="{ errors }" rules="required" name="name">
                                <v-text-field
                                  v-model="item.name"
                                  label="Name"
                                  :error-messages="errors"
                                  outlined
                                  required
                                  hint="Name zum Sound"
                                ></v-text-field>
                              </ValidationProvider>
                              <ValidationProvider v-slot="{ errors }" rules="required" name="link">
                                <v-text-field
                                  v-model="item.url"
                                  label="Link"
                                  :error-messages="errors"
                                  outlined
                                  required
                                  hint="Link zum Sound (Youtube oder Soundcloud)"
                                ></v-text-field>
                              </ValidationProvider>
                            </ValidationObserver>
                            <div>
                              <v-btn class="mr-4" @click="onSave(item, index)">
                                <v-icon left color="yellow darken-3">
                                  fa-save
                                </v-icon>
                                Save
                              </v-btn>
                              <v-btn @click="onCancel(item)">
                                <v-icon left color="yellow darken-3">
                                  fa-times-circle
                                </v-icon>
                                cancel
                              </v-btn>
                            </div>
                          </v-list-item-content>
                        </div>
                      </v-list-item>
                      <v-divider v-if="index < playlist.length - 1" :key="index"></v-divider>
                    </div>
                  </transition-group>
                </draggable>
              </v-list>
            </v-card-text>
            <v-card-actions>
              <v-btn class="ml-auto" color="primary" @click="addItem">
                <v-icon left small>
                  fa-plus
                </v-icon>
                Link hinzufügen
              </v-btn>
            </v-card-actions>
          </v-skeleton-loader>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import Topbar from '../components/Topbar';
  import {ValidationObserver, ValidationProvider} from 'vee-validate';
  import draggable from 'vuedraggable';
  import { nanoid } from 'nanoid';

  export default {
    name: 'DiscordBot',

    components: {
      ValidationObserver,
      ValidationProvider,
      Topbar,
      draggable
    },
    data() {
      return {
        drag: true,
        editActive: [],
        navbarTitle: 'Discord Bot Playlist',
        playlist: [
          {
            id: "d44cc43e-7d6a-4ed4-a496-116be31d7faa",
            name: "Coffee Shop",
            url: "https://www.youtube.com/watch?v=-5KAN9_CzSA",
            userId: "233247149448626176",
            userName: "[WB] IrraIvan / Tom"
          },
          {
            id: "d44cxcxc43e-7d6a-4ed4-a496-116be31d7faa",
            name: "Music Shop",
            url: "https://www.youtube.com/watch?v=-5KAN9_CzSA",
            userId: "233247149448626176",
            userName: "[WB] IrraIvan / Tom"
          },
        ]
      };
    },

    computed: {
      loading() {
        return this.$store.getters.loading;
      },
      dragOptions() {
        return {
          animation: 200,
          disabled: false,
          ghostClass: 'ghost'
        };
      }
    },

    methods: {
      async onSave(item, index) {
        const formValid = await this.$refs.observer[index].validate();
        if (!formValid) {
          return;
        }
        this.editActive = this.editActive.filter((itm) => itm !== item.id);
        let form = JSON.parse(JSON.stringify(item));
        if (item.newItem) {
          delete form.newItem;
        }
        // send form
      },
      onCancel(item) {
        this.editActive = this.editActive.filter((itm) => itm !== item.id);
        if (item.newItem) {
          this.playlist = this.playlist.filter((itm) => itm.id !== item.id);
        }
      },
      addItem() {
        const newItem = {
          id: nanoid(),
          name: '',
          url: '',
          userId: '',
          userName: '',
          newItem: true
        }
        this.editActive.push(newItem.id);
        this.playlist.push(newItem);
        // add playlist
      },
      onEdit(id) {
        if (this.editActive.includes(id)) {
          this.editActive = this.editActive.filter((item) => item !== id);
          return
        }
        this.editActive.push(id);
      },
      onDelete(id) {
        // await delete item
        this.editActive = this.editActive.filter((item) => item !== id);
      },
      onDragEnd() {
        this.drag = false;
        // AWAIT SAVE CHANGES
        this.drag = true;
      }
    }
  };
</script>

<style lang="scss">
  .move--btn {
    cursor: move !important;
    margin-right: auto;
  }
</style>
